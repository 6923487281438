<div class="cta-colored-box cta-centered-box">
  <event-status *ngIf="auction" [auction]="auction"></event-status>
</div>
<!-- Available and price -->
<div class="cta-box cta-box-column-content">
  <p class="mat-h5 dark">
    {{
      'event.price'
        | translate
          : { price: (item?.minimumBid | currency : currency : '' : '1.2-2') }
    }}&nbsp;<span class="currency">{{ currency }}</span>
  </p>
  <p *ngIf="item?.itemState === 'open'">
    {{
      (item?.itemType === 'ticket'
        ? 'item.ticketsAvailable'
        : 'item.itemsAvailable'
      ) | translate
    }}: {{ (item?.maxItems || 0) - (item?.purchasedCount || 0) }}
  </p>
  <p *ngIf="item?.itemState === 'open'">
    {{ 'item.maxPerPurchase' | translate }}: {{ item?.maxPurchases }}
  </p>
  <p *ngIf="item?.itemState === 'open' && (pickupAvailable || shippingAvailable)">
    {{pickupAvailable ? ('shipping.pickup' | translate) : ''}}
    {{(pickupAvailable && shippingAvailable) ? ' & ' : ''}}
    {{shippingAvailable ? ('shipping.shipping' | translate) : ''}}
    {{' ' + ((pickupAvailable && shippingAvailable ? 'general.available' : 'general.only') | translate)}}
  </p>
  <ng-container *ngIf="!requireFulfillmentMethod && buyNowItem && !purchaseCompleted">
    <fulfillment-option-info [item]="buyNowItem"></fulfillment-option-info>
  </ng-container>
</div>
<!-- Buy now -->
 <ng-container *ngIf="!requireFulfillmentMethod">
   <div class="cta-box" *ngIf="item?.itemState === 'open'">
     <button
       *ngIf="!buying"
       mat-raised-button
       color="primary"
       id="pre-buy"
       [disabled]="processing$ | async"
       (click)="buying = true"
     >
       {{ 'event.preBuy' | translate | uppercase }}
     </button>
     <form *ngIf="buying">
       <button
         mat-mini-fab
         aria-label="Minus one item"
         color="primary"
         [disabled]="processing$ | async"
         (click)="subtractItem()"
       >
         <mat-icon>remove</mat-icon>
       </button>
       <div class="totals">
         <div class="mat-h5 dark">{{ qty }}</div>
         <div>
           {{
             qty * (item?.minimumBid || 0) | currency : currency : 'symbol' : '1.2-2'
           }}&nbsp;<span class="currency">{{ currency }}</span>
         </div>
       </div>
       <button
         mat-mini-fab
         aria-label="Plus one item"
         (click)="addItem()"
         [disabled]="processing$ | async"
         color="primary"
       >
         <mat-icon>add</mat-icon>
       </button>
       <button
         mat-raised-button
         color="primary"
         id="buy"
         [disabled]="processing$ | async"
         (click)="submit()"
       >
         {{ 'event.buyConfirm' | translate | uppercase }}
       </button>
     </form>
     <payment
       *ngIf="currency && profile"
       [segment]="{ currency: currency, foundationId: foundation?.id }"
       (cardSelected)="cardSelected($event)"
       [card]="card"
     ></payment>
     <div class="mat-subtitle-4" *ngIf="!card">
       {{ 'event.nodHint' | translate }}
     </div>
     <!-- Open Item Comments -->
     <div class="mat-subtitle-4">
       {{ 'event.binNoteFee1' | translate }}
     </div>
     <div class="mat-subtitle-4">
       {{
         'event.noteFee2'
           | translate : { procPerc: foundation?.procPerc, name: foundation?.name }
       }}
     </div>
     <div class="mat-subtitle-4">
       {{
         'event.noteLocation'
           | translate : { location: currency === 'CAD' ? 'Canada' : 'U.S.A.' }
       }}
     </div>
     <div class="mat-subtitle-4">
       {{ 'event.noteTos' | translate }}
     </div>
   </div>
 </ng-container>
 <div class="cta-box" *ngIf="buyNowItem && requireFulfillmentMethod">
    <fulfillment-option
      [item]="buyNowItem"
      [requireFulfillmentMethod]="true"
      (onFulfillmentOptionUpdated)="confirmFulfillmentMethod($event)"
      (editState)="fulfillmentOptionState($event)">
    </fulfillment-option>
  </div>
<mat-progress-bar mode="query" *ngIf="processing$ | async"></mat-progress-bar>
