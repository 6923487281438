import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, inject } from '@angular/core';
import { GlobalState } from '@fan-store/state';
import { selectUserProcessing, selectUserState } from '@fan-store/user/user.selectors';
import { Currency } from '@fan-types/currency';
import { FulfillmentOption } from '@fan-types/fulfillment-option';
import { ItemType } from '@fan-types/item-type.enum';
import { AuctionItem, BuynowItem } from '@fan-types/item.model';
import { ProfileAddress, UserProfile } from '@fan-types/user-profile';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fulfillment-option',
  templateUrl: './fulfillment-option.component.html',
  styleUrls: ['./fulfillment-option.component.scss']
})
export class FulfillmentOptionComponent implements OnDestroy, OnChanges {
  @Input() item!: AuctionItem | BuynowItem;
  @Input() currency?: Currency = 'USD';
  @Input() requireFulfillmentMethod = false;;
  @Output() onFulfillmentOptionUpdated = new EventEmitter<{option: Partial<FulfillmentOption>, address?: ProfileAddress}>();
  @Output() editState = new EventEmitter<string>();

  readonly #translateService = inject(TranslateService);

  profile?: UserProfile;
  subs?: Subscription[];

  fulfillmentOptions: Partial<FulfillmentOption>[] = [];
  fulfillmentOption?: Partial<FulfillmentOption>;
  addressSelected?: ProfileAddress;
  addresses?: ProfileAddress[];
  showAddressSelector = false;
  showNewAddressComponent = false;
  showButtons = false;
  showDefaultOption = false;
  showOnlyChangeAddressButton = false;

  waitingAddressCreation = false;



  constructor(private store: Store<GlobalState>) {
    const profileSub = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) {
        this.profile = { ...profile };
        this.addresses = [...(this.profile.addresses ?? [])];
        if (this.waitingAddressCreation) {
          this.addressSelected = [...(this.addresses ?? [])].pop();
        }
        if (!this.addressSelected) {
          this.addressSelected = this.addresses?.find(address => address.default) ?? this.addresses?.[0];
          if (!this.addressSelected) {
            this.showAddressSelector = false;
            this.showNewAddressComponent = true;
          }
        }
        this.addresses.push({
          id: 'add-new',
          name: this.#translateService.instant('shipping.addNew'),
        });
      }
    });
    this.subs?.push(profileSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item?.currentValue) {
      const itemUpdates = changes.item?.currentValue as AuctionItem;
      if (!this.fulfillmentOption) {
        this.fulfillmentOptions = itemUpdates.fulfillmentOptions ?? [];
        this.fulfillmentOption = itemUpdates.fulfillmentOptionSelected?.option;
        this.addressSelected = itemUpdates.fulfillmentOptionSelected?.address;
        this.showDefaultOption = false;

        // Add fulfillment option if not present in item options
        if (this.fulfillmentOption && !this.fulfillmentOptions.find(
          (option: Partial<FulfillmentOption>) => option.id === this.fulfillmentOption?.id || option.name === this.fulfillmentOption?.name)
        ) {
          this.fulfillmentOptions.push(this.fulfillmentOption);
        }

        // Set Default option
        if (this.fulfillmentOptions?.length === 1) {
          this.fulfillmentOption = this.fulfillmentOptions[0];
          this.showDefaultOption = true;

          if (this.fulfillmentOption.type === 'shipping') {
            if (!this.addressSelected) {
              this.addressSelected = {...(
                this.profile?.addresses?.find(address => address.default) ?? this.profile?.addresses?.[0] ?? {}
              )};
            }
            this.showButtons = true;
            if (itemUpdates.itemType === ItemType.Auction) {
              this.showOnlyChangeAddressButton = true;
            }
          }
        }
      }
    }
    if (changes.requireFulfillmentMethod?.currentValue) {
      this.showButtons = changes.requireFulfillmentMethod.currentValue;
    }

  }

  ngOnDestroy(): void {
    this.subs?.forEach(sub => sub.unsubscribe());
    this.editState.emit('destroyed');
  }

  onOptionSelected(option: Partial<FulfillmentOption>) {
    this.showAddressSelector = false;
    if (this.item && this.item.fulfillmentOptionSelected?.option?.name !== option.name) {
      this.showButtons = true;
      this.editState.emit('start');
      if (option.type === 'shipping' && !this.addressSelected) {
        this.addressSelected = {...(
          this.profile?.addresses?.find(address => address.default) ?? this.profile?.addresses?.[0] ?? {}
        )};
      }
    }
  }

  onAddressSelected(address: ProfileAddress) {
    this.showAddressSelector = false;
    this.showOnlyChangeAddressButton = false;
    if (address.id === 'add-new') {
      this.showNewAddressComponent = true;
      this.showButtons = false;
      this.waitingAddressCreation = true;
    } else {
      this.showButtons = true;
    }
  }

  changeAddress() {
    this.showAddressSelector = !!(this.addresses?.length && this.addresses?.length > 2);
    if (!this.showAddressSelector) {
      this.showButtons = false;
      this.showNewAddressComponent = true;
      this.waitingAddressCreation = true;
    }
  }

  onAddressCreated(created: boolean) {
    this.showNewAddressComponent = false;
    this.showButtons = true;
    if (!created) {
      this.waitingAddressCreation = false;
      this.addressSelected = this.item.fulfillmentOptionSelected?.address ?? this.profile?.addresses?.find(address => address.default) ?? this.profile?.addresses?.[0];
    }
  }

  confirmFulfillmentMethod() {
    this.showButtons = false;
    this.showAddressSelector = false;
    this.showNewAddressComponent = false;
    if (this.fulfillmentOption) {
      this.editState.emit('completed');
      this.onFulfillmentOptionUpdated.emit({option: this.fulfillmentOption, address: this.addressSelected});
    }
  }

  objectComparisonFunction = (option: Partial<FulfillmentOption>, value: Partial<FulfillmentOption>) => {
    return !!(option && value && (option.id === value.id || option.name === value.name));
  }

  validateMethod() {
    if (!this.requireFulfillmentMethod) return true;
    if (!this.fulfillmentOption) return false;
    return this.fulfillmentOption?.type === 'shipping' ? !!(this.addressSelected?.name) : true;
  }

}
