import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { buildItemPath } from '../../../../helpers/build-item-path';
import { GlobalState } from '../../../../store';
import { processBid } from '../../../../store/bid/bid.actions';
import { selectBidCompleted, selectBidProcessing } from '../../../../store/bid/bid.selectors';
import { selectUserState } from '../../../../store/user/user.selectors';
import {
  Auction,
  AuctionItem,
  CCard,
  Currency,
  FulfillmentOption,
  FulfillmentOptionType,
  ItemState,
  ProfileAddress,
  UserProfile,
} from '../../../../types';
import { Foundation } from '../../../../types/foundation.model';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import {
  OnboardingDialogComponent,
  OnboardingDialogData,
} from '../onboarding-dialog/onboarding-dialog.component';
import profileHasAddress from '../../../../helpers/profile-has-address';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';
import { ApiService } from '../../../../services/api.service';
import { pullItem } from '../../../../store/item/item.actions';
import { BidConfirmDialogComponent, BidConfirmDialogData } from './bid-confirm-dialog/bid-confirm-dialog.component';
import { BidCompleteDialogComponent } from './bid-complete-dialog/bid-complete-dialog.component';
import { BidBuynowFulfillmentMethodDialogComponent } from './bid-buynow-fulfillment-method-dialog/bid-buynow-fulfillment-method-dialog.component';

@Component({
  selector: 'bid',
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.scss'],
})
export class BidComponent implements OnDestroy, OnChanges {
  currency?: Currency;
  foundation?: Foundation;
  nextBid?: number;
  bid?: number;
  subs: Subscription[] = [];
  profile?: UserProfile;
  card?: CCard;
  processing$ = this.store.select(selectBidProcessing);
  paying = false;
  isTopBidder = false;
  needPayment = false;

  showBuynowOption = false;
  buying = false;
  confirmPending = false;
  editingFulfillmentMethod = false;

  bidAlreadyExists = false;
  pickupAvailable = false;
  shippingAvailable = false;

  private _item?: AuctionItem | undefined;
  public get item(): AuctionItem | undefined {
    return this._item;
  }
  @Input()
  public set item(value: AuctionItem | undefined) {
    this._item = value;
    this.calcNextBid();
    this.updateFlags();
    this.showBuyNow();
  }

  private _auction?: Auction | undefined | null;
  public get auction(): Auction | undefined | null {
    return this._auction;
  }
  @Input()
  public set auction(value: Auction | undefined | null) {
    this._auction = value;
    this.foundation = this.auction?.foundation;
    this.currency = this.auction?.foundation?.currency ?? 'USD';
    this.showBuyNow();
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService,
    private api: ApiService
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) {
        this.profile = { ...profile };
        this.updateFlags();
      }
    });
    this.subs.push(subs);

    // Listen for bid completion
    const bidCompleted = this.store
      .select(selectBidCompleted)
      .subscribe((completed) => {
        if (completed && this.confirmPending) {
          this.showCompleteBidDialog();
        }
      });
    this.subs.push(bidCompleted);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item?.currentValue) {
      this.pickupAvailable = !!changes.item.currentValue?.fulfillmentOptions?.find((option: FulfillmentOption) => option.type === FulfillmentOptionType.pickup);
      this.shippingAvailable = !!changes.item.currentValue?.fulfillmentOptions?.find((option: FulfillmentOption) => option.type === FulfillmentOptionType.shipping);
    }
  }

  private calcNextBid() {
    if (!this.item) {
      this.nextBid = 0;
      return;
    }
    const { increment, topBidAmount, minimumBid } = this.item;
    const current = topBidAmount ? topBidAmount : minimumBid;
    if (!current || !increment) {
      this.nextBid = 0;
      return;
    }
    const bidAmount = topBidAmount ? current + increment : current;
    this.nextBid = Math.round(bidAmount * 100) / 100;
    if (this.nextBid) this.bid = this.nextBid;
  }

  notOnboarded() {
    if (!this.currency) return;
    const dialogRef = this.dialog.open<
      OnboardingDialogComponent,
      OnboardingDialogData
    >(OnboardingDialogComponent, {
      data: { currency: this.currency, foundationId: this.foundation?.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const data: ConfirmDialogData = {
        confirmKey: result.isNewProfile
          ? 'bid.newAccount'
          : 'bid.existingAccount',
        okKey: 'modal.ok',
        hideCancel: true,
        bodyKey: 'bid.goBid',
      };
      this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      if (!this.card) this.card = result.card;
    });
  }

  submit(isBuyNow?: boolean, fulfillmentMethod?: {fulfillmentOption: Partial<FulfillmentOption>, shipAddress?: ProfileAddress}) {
    if (!this.currency || !this.bid || !this.item?.id) return;
    this.trackAnalytics.bid(AnalyticsEvents.BidStart, this.item.id, this.bid);
    if (!this.card || !profileHasAddress(this.profile))
      return this.notOnboarded();
    if (!isBuyNow) {
      return this.confirmBid();
    }
    this.performBid(true, fulfillmentMethod);
  }

  confirmPurchase() {
    if (this.item?.fulfillmentOptions?.length) {
      const dialogRefFulfillmentMethod = this.dialog.open(BidBuynowFulfillmentMethodDialogComponent, {
        data: {item: this.item, currency: this.currency},
        minWidth: '360px',
      });
      dialogRefFulfillmentMethod.afterClosed().subscribe((result) => {
        if (result?.method) {
          this.submit(true, result.method);
        }
      });
    } else {
      const data: ConfirmDialogData = {
        confirmKey: 'bid.confirmPurchase',
        okKey: 'event.preBuy',
      };
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.submit(true);
        }
      });
    }
  }

  confirmBid() {
    if (this.bidAlreadyExists) {
      this.performBid(false);
    } else {
      this.api.bid.validateFirstBid(this.item?.id!)
        .subscribe(res => {
          if (res) {
            // Show confirm dialog
            const data: BidConfirmDialogData = {
              bid: this.bid!,
              item: this.item!
            };
            const dialogRef = this.dialog.open(BidConfirmDialogComponent, {
              data,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.bid = result;
                this.performBid(false);
              }
            });
          } else {
            // Not initial bid, we'll avoid the confirmation dialog
            this.performBid(false);
          }
        })
    }

  }

  showCompleteBidDialog() {
    this.confirmPending = false;
    const confirmDialog = this.dialog.open(BidCompleteDialogComponent, {data: {fulfillmentOptions: this.item?.fulfillmentOptions}});
    const afterCloseSub = confirmDialog.afterClosed().subscribe(() => {
      if (this.item?.id) {
        this.store.dispatch(pullItem({ itemId: this.item.id }));
        afterCloseSub?.unsubscribe();
      }
    });
  }

  performBid(isBuyNow: boolean, fulfillmentMethod?: {fulfillmentOption: Partial<FulfillmentOption>, shipAddress?: ProfileAddress}) {
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );
    if (!isBuyNow) {
      this.confirmPending = true;
    }
    this.store.dispatch(
      processBid({
        bid: {
          step: 'saving',
          amount: this.bid!,
          currency: this.currency!,
          item: this.item!,
          itemPath: [...routes, 'item', this.item?.id!],
          type: 'bid',
          card: this.card,
          buynowAmount: isBuyNow ? this.item?.buynowAmount : undefined,
          fulfillmentOption: isBuyNow ? fulfillmentMethod?.fulfillmentOption : undefined,
          shipAddress: isBuyNow ? fulfillmentMethod?.shipAddress : undefined
        },
      })
    );
  }

  private updateFlags() {
    this.isTopBidder = (() => {
      if (!this.profile?.id) return false;
      if (!this.item?.topBidUserProfileId) return false;
      return this.profile?.id === this.item?.topBidUserProfileId;
    })();
    this.bidAlreadyExists = (() => {
      if (this.isTopBidder || this.item?.userAlreadyBid || this.item?.fulfillmentOptionSelected) {
        return true;
      } else {
        return false;
      }
    })();
    this.needPayment =
      this.isTopBidder && this.item?.itemState === ItemState.Closed;
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
  cardSelected(card: CCard) {
    setTimeout(() => (this.card = card));
  }
  topBidderCardSelected(card: CCard) {
    const itemId = this.item?.id ?? '';
    if (!itemId || !card.cardId) return;
    setTimeout(() => (this.card = card));
    this.api.auctionItem.updatePaymentMethod(itemId, card.cardId).subscribe(
      () => this.store.dispatch(pullItem({ itemId })),
      (err) => {
        this.paying = false;
        const modalMessage =
          'There was an error updating the payment. ' +
          err?.error?.error?.message;
        const data: ConfirmDialogData = {
          confirmText: modalMessage,
          okKey: 'modal.ok',
          hideCancel: true,
        };
        this.dialog.open(ConfirmDialogComponent, {
          data,
        });
        this.store.dispatch(pullItem({ itemId }));
      }
    );
  }
  winnerPay(card: CCard) {
    const itemId = this.item?.id ?? '';
    if (!this.profile?.id || !itemId) return;
    this.paying = true;
    this.api.user
      .chargeCard(this.profile.id, { itemId, cardId: card.id })
      .subscribe(
        () => {
          this.store.dispatch(pullItem({ itemId }));
          this.paying = false;
          const data: ConfirmDialogData = {
            confirmKey: 'modal.paymentDone',
            okKey: 'modal.ok',
            hideCancel: true,
          };
          this.dialog.open(ConfirmDialogComponent, {
            data,
          });
        },
        (err) => {
          this.paying = false;
          const modalMessage =
            'There was an error processing the charge. ' +
            err?.error?.error?.message;
          const data: ConfirmDialogData = {
            confirmText: modalMessage,
            okKey: 'modal.ok',
            hideCancel: true,
          };
          this.dialog.open(ConfirmDialogComponent, {
            data,
          });
        }
      );
  }

  showBuyNow() {
    if (!this.item || !this.auction) return;
    const diff =
      new Date(this.auction?.auctionEnd ?? '').getTime() - new Date().getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    this.showBuynowOption =
      (this.bid ?? 0) < (this.item?.buynowAmount ?? 0) &&
      this.item?.buynowAmount &&
      hours >= (this.item?.buynowMinHours ?? 24)
        ? true
        : false;
  }

  updateFulfillmentOption(event: {option: Partial<FulfillmentOption>, address?: ProfileAddress}) {
    if (!this.item?.id) return;
    this.api.auctionItem.updateFulfillmentMethod(this.item?.id, event.option, event.address)
      .subscribe((res)=>{
        if (res.updated && this.item?.id) {
          this.store.dispatch(pullItem({ itemId: this.item.id }));
        }
      }, (err) => {
        const message = `There was an error updating the fulfillment method: ${err?.error?.error?.message ?? err?.message}`;
        const data: ConfirmDialogData = {
          confirmText: message,
          okKey: 'modal.ok',
          hideCancel: true,
        };
        this.dialog.open(ConfirmDialogComponent, {
          data,
        });
      })
  }

  fulfillmentMethodStateChanged(event: string) {
    this.editingFulfillmentMethod = event === 'start';
  }
}
