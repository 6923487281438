<div class="cta-colored-box cta-centered-box">
  <event-status *ngIf="auction" [auction]="auction"></event-status>
  <!-- {{ item?.title }} -->
  <div *ngIf="isTopBidder">
    {{ 'event.topBidder' | translate }}
    {{ item?.topBidAmount || 0 | currency : currency : 'symbol' : '1.2-2' }}
    <span class="currency">{{ currency }}</span>
  </div>
</div>
<!-- Starting Bid -->
<div class="cta-box cta-box-column-content">
  <p class="mat-h5 dark">
    <ng-container *ngIf="item?.itemState === 'pending'">
      {{ 'event.startingBid' | translate }}:
      {{ item?.minimumBid | currency : currency : 'symbol' : '1.2-2' }}
    </ng-container>
    <ng-container
      *ngIf="
        item?.itemState === 'closed' ||
        item?.itemState === 'closing' ||
        item?.itemState === 'paid'
      "
    >
      {{ 'event.winningBid' | translate }}:
      {{ item?.topBidAmount || 0 | currency : currency : 'symbol' : '1.2-2' }}
    </ng-container>
    <ng-container *ngIf="item?.itemState === 'open'">
      {{ 'event.currentBid' | translate }}:
      {{ item?.topBidAmount || 0 | currency : currency : 'symbol' : '1.2-2' }}
    </ng-container>
    <span class="currency">{{ currency }}</span>
  </p>
  <p>
    {{ 'event.increments' | translate }}:
    {{ item?.increment | currency : currency : 'symbol' : '1.2-2' }}
    <span class="currency">{{ currency }}</span>
  </p>

  <p *ngIf="item?.itemState === 'open' && !isTopBidder">
    {{ 'event.nextBid' | translate }}:
    {{ nextBid | currency : currency : 'symbol' : '1.2-2' }}
    <span class="currency">{{ currency }}</span>
  </p>

  <p *ngIf="item?.itemState === 'open' && item?.willGoOT" class="dark">
    🚨 {{ 'overtime.willGoOT' | translate }} 🚨
  </p>

  <div *ngIf="item?.bidCount && item?.itemState !== 'pending'">
    {{ 'event.bidsCount' | translate }}:&nbsp;{{ item?.bidCount }}
  </div>

  <p *ngIf="item?.itemState === 'open' && (pickupAvailable || shippingAvailable)">
    {{pickupAvailable ? ('shipping.pickup' | translate) : ''}}
    {{(pickupAvailable && shippingAvailable) ? ' & ' : ''}}
    {{shippingAvailable ? ('shipping.shipping' | translate) : ''}}
    {{' ' + ((pickupAvailable && shippingAvailable ? 'general.available' : 'general.only') | translate)}}
  </p>

  <ng-container *ngIf="item && item?.itemState === 'closed' || item?.itemState === 'paid'">
    <fulfillment-option-info [item]="item"></fulfillment-option-info>
  </ng-container>
</div>
<!-- winner -->
<winner-message [item]="item" [profileId]="profile?.id"></winner-message>
<!-- Bid -->
<div class="cta-box" *ngIf="needPayment && !paying">
  <payment
    *ngIf="currency && profile"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelectedFinished)="winnerPay($event)"
    [card]="card"
    [useCustomUI]="true"
    class="w-100 block"
  >
    <button
      mat-raised-button
      color="primary"
      class="mb w-100"
      [disabled]="processing$ | async"
    >
      {{ 'action.pay' | translate | uppercase }}
    </button>
  </payment>
</div>
<!-- Bid -->
<div class="cta-box" *ngIf="item?.itemState === 'open' && nextBid">
  <p class="mat-h5 dark" *ngIf="isTopBidder">
    {{ 'modal.topBidderError' | translate }}
  </p>
  <form>
    <mat-form-field appearance="outline" *ngIf="!isTopBidder">
      <mat-icon matPrefix>attach_money</mat-icon>
      <input
        cdkFocusInitial
        matInput
        type="number"
        [(ngModel)]="bid"
        name="bidAmount"
        [readonly]="processing$ | async"
      />
    </mat-form-field>
    <button
      *ngIf="!isTopBidder"
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="processing$ | async"
      type="submit"
      (click)="submit()"
    >
      {{ 'action.bid' | translate | uppercase }}
    </button>
  </form>

  <!-- Buy now auction item -->
  <div *ngIf="showBuynowOption" class="buynow-actions">
    <span class="mat-h5 or-text">{{
      'general.or' | translate | uppercase
    }}</span>
    <button
      *ngIf="!buying"
      mat-stroked-button
      color="primary"
      [disabled]="processing$ | async"
      (click)="buying = true"
    >
      {{ 'event.bin' | translate | uppercase }} -
      {{ item?.buynowAmount | currency : currency : 'symbol' : '1.2-2' }}
      {{ currency }}
    </button>
    <button
      *ngIf="buying"
      mat-raised-button
      color="primary"
      class="mb"
      [disabled]="processing$ | async"
      (click)="confirmPurchase()"
    >
      {{ 'event.buyConfirm' | translate | uppercase }} -
      {{ item?.buynowAmount | currency : currency : 'symbol' : '1.2-2' }}
      {{ currency }}
    </button>
  </div>

  <payment
    *ngIf="currency && profile && !isTopBidder"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelected)="cardSelected($event)"
    [card]="card"
  ></payment>
  <payment
    *ngIf="currency && profile && isTopBidder"
    [segment]="{ currency: currency, foundationId: foundation?.id }"
    (cardSelected)="cardSelected($event)"
    (cardSelectedFinished)="topBidderCardSelected($event)"
    [card]="card"
  ></payment>
  <!-- Fulfillment method -->
  <ng-container *ngIf="item && item.itemState === 'open' && bidAlreadyExists">
    <fulfillment-option
      [item]="item"
      [currency]="currency"
      (onFulfillmentOptionUpdated)="updateFulfillmentOption($event)"
      (editState)="fulfillmentMethodStateChanged($event)">
    </fulfillment-option>
  </ng-container>
  <!-- Extra info section -->
  <ng-container *ngIf="!editingFulfillmentMethod">
    <div class="mat-subtitle-4 buynow-warning" *ngIf="showBuynowOption">
      {{ 'event.buynowWarning' | translate: {hours: (item?.buynowMinHours ?? 24), plural: (item?.buynowMinHours === 1 ? '' : 'S')} }}
    </div>
    <div class="mat-subtitle-4" *ngIf="!card">
      {{ 'event.nodHint' | translate }}
    </div>
    <!-- Open Item Comments -->
    <div class="mat-subtitle-4 mt">
      {{ 'event.noteFee1' | translate }}
    </div>
    <div class="mat-subtitle-4">
      {{
        'event.noteFee2'
          | translate : { procPerc: foundation?.procPerc, name: foundation?.name }
      }}
    </div>
    <div class="mat-subtitle-4">
      {{
        'event.noteLocation'
          | translate : { location: currency === 'CAD' ? 'Canada' : 'U.S.A.' }
      }}
    </div>
    <div class="mat-subtitle-4">
      {{ 'event.noteOt' | translate
      }}<a
        href
        (click)="tooltip.show(); (false)"
        aria-label="Show tooltip"
        #tooltip="matTooltip"
        class="gray-7"
        [matTooltip]="'event.noteOtHelp' | translate"
        >{{ 'event.noteOtLink' | translate }}</a
      >
    </div>
    <div class="mat-subtitle-4">
      {{ 'event.noteTos' | translate }}
    </div>
  </ng-container>
</div>
<mat-progress-bar
  mode="query"
  *ngIf="(processing$ | async) || paying"
></mat-progress-bar>
