import { Item, ItemState, ItemType } from '../types';

export function isSoldOut(item: Item | undefined) {
  if (!item) return false;
  if (
    item.itemType === ItemType.Auction &&
    item.itemState === ItemState.Paid &&
    item.buynowAmount === item.topBidAmount
  ) {
    return true;
  }
  if (item.itemType !== ItemType.Buynow && item.itemType !== ItemType.Ticket)
    return false;
  if (item.itemState === ItemState.Sold) return true;
  const quantityAvailable = item.maxItems - (item.purchasedCount ?? 0);
  if (quantityAvailable === 0) return true;
  return false;
}
