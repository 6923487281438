import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from './version';

Sentry.init({
  dsn: environment.sentryDns,
  tracesSampleRate: 0.0,
  release: version,
  ignoreErrors: [
    /Object Not Found Matching Id/i,
    /Non-Error exception captured with keys/i
  ]
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
