export const environment = {
  production: false,
  apiUrl: 'https://dev-api.winwithdash.com/v4',
  pubKey: 'pub-c-f4e822f7-550d-462a-ab87-a1a867cc3e75',
  subKey: 'sub-c-8626c0dc-4bf6-11e5-b316-0619f8945a4f',
  mixpanelToken: 'fbcd1d13ad465f00f9f40c23049c070d',
  assetsUrl: 'https://dev-assets.winwithdash.com',
  appId: 'dash',
  sentryDns:
    'https://431f65c5cb6f449f820ead98cf1a48fb@o138343.ingest.sentry.io/6189400',
  firebase: {
    apiKey: 'AIzaSyBaAqbS3hhaZegb5xmqpZlcmjRN-W3nqwM',
    authDomain: 'dash-production.firebaseapp.com',
    databaseURL: 'https://dash-production.firebaseio.com',
    projectId: 'dash-production',
    storageBucket: 'dash-production.appspot.com',
    messagingSenderId: '1058188252524',
    appId: '1:1058188252524:web:4a7045334104b4b4eacf49',
    measurementId: 'G-B2E3FK60HN',
    vapidKey:
      'BFvprYc4OrPY44hTveO_qapEaorBiZc954WkoSoNDQWqSjeef3OEIph_1qJ6d_Da1zw_bFM17M8cm0Jt0QfaKa0',
  },
};
