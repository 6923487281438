import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data } from '@angular/router';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { waitFor } from '../helpers/wait-for-it';
import { GlobalState } from '../store';
import { selectById as selectEventById } from '../store/auction/auction.reducer';
import { selectById as selectItemById } from '../store/item/item.reducer';
import { selectEventByItemId } from '../store/selectors';
import { Auction, AuctionItem, Item, TeamResult } from '../types';
import { AnalyticsEvents } from './analytics-events.enum';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class TrackAnalyticsService {
  private data?: Data;
  constructor(
    private store: Store<GlobalState>,
    private analytics: AnalyticsService
  ) {}

  eventAction(
    action: AnalyticsEvents.ViewAuctionItems | AnalyticsEvents.ViewFeaturedItem,
    eventId: string,
    props: Dict = {}
  ) {
    const context = this.data?.segments?.[0] ?? 'dash';
    of(eventId)
      .pipe(
        waitFor(this.store.select(selectEventById(eventId))),
        tap((event) => {
          if (!event) return;
          this.analytics.track(action, {
            ...props,
            auctionId: event.id,
            auctionName: event.title,
            eventLang: event.lang,
            eventCurrency: event.foundation?.currency ?? 'USD',
            auctionState: event.auctionState,
            foundationId: event.foundationId,
            foundationName: event.foundation?.name,
            context,
          });
        })
      )
      .subscribe();
  }

  itemAction(
    action: 'view' | AnalyticsEvents,
    itemId: string,
    props: Dict = {}
  ) {
    let item: Item | undefined;
    const context = this.data?.segments?.[0] ?? 'dash';
    of(itemId)
      .pipe(
        waitFor(this.store.select(selectItemById(itemId))),
        tap((i) => (item = i)),
        waitFor(this.store.select(selectEventByItemId(itemId))),
        tap((event) => {
          if (!event || !item) return;
          const eventType = (() => {
            if (action === 'view')
              return 'overtimeImIn' in item && item.increment
                ? AnalyticsEvents.OvertimeViewAuctionItemDetail
                : AnalyticsEvents.ViewAuctionItemDetail;
            return action;
          })();
          this.analytics.track(eventType, {
            ...props,
            auctionItemId: item.id,
            auctionItemName: item.title,
            itemType: item.itemType,
            minimumBid: item.minimumBid,
            auctionId: event.id,
            auctionName: event.title,
            eventLang: event.lang,
            eventCurrency: event.foundation?.currency ?? 'USD',
            auctionState: event.auctionState,
            foundationId: event.foundationId,
            foundationName: event.foundation?.name,
            context,
          });
        })
      )
      .subscribe();
  }

  nav(snapshot: ActivatedRouteSnapshot) {
    const { data, params } = snapshot;
    if (!data) return;
    this.data = data;
    switch (data.path) {
      case 'event':
        return this.eventAction(AnalyticsEvents.ViewAuctionItems, params.id);
      case 'item':
        return this.itemAction('view', params.id);
    }
    if (!data.analyticsEvent) return;
    const eventParams: Dict = {};
    for (const p in data.analyticsParams) eventParams[p] = params[p];
    return this.analytics.track(data.analyticsEvent, eventParams);
  }

  bid(
    action:
      | AnalyticsEvents.BidStart
      | AnalyticsEvents.OvertimeBidStart
      | AnalyticsEvents.BidSuccess
      | AnalyticsEvents.OvertimeBidSuccess,
    itemId: string,
    amount: number
  ) {
    this.itemAction(action, itemId, {
      amount,
    });
  }

  buy(
    action: AnalyticsEvents.AuctionItemPaid | AnalyticsEvents.StartPurchase,
    itemId: string,
    quantity: number
  ) {
    this.itemAction(action, itemId, {
      quantity,
    });
  }

  enterGiveaway(itemId: string) {
    this.itemAction(AnalyticsEvents.EnterGiveaway, itemId);
  }
  viewFeatured(params: Dict) {
    return this.analytics.track(AnalyticsEvents.ViewFeaturedItem, params);
  }

  donate(
    action: AnalyticsEvents.Donate | AnalyticsEvents.StartDonate,
    itemId: string,
    amount: number
  ) {
    this.itemAction(action, itemId, {
      amount,
    });
  }

  search(
    type: AnalyticsEvents.SearchResultSelected | AnalyticsEvents.SearchCancelled,
    query: string,
    item?: AuctionItem | Auction | TeamResult,
    numResults?: number
  ) {
    let params:Dict = {};
    if (numResults !== undefined && numResults >= 0) {
      params = {
        query,
        numResults
      }
    } else if (item){
      params['query'] = query;
      params['itemRef'] = item.id;
      if ('itemType' in item) {
        params['itemType'] = 'item';
        params['itemTitle'] = item.title;
      } else if ('auctionState' in item) {
        params['itemType'] = 'auction';
        params['itemTitle'] = item.title;
      } else {
        params['itemType'] = 'team';
        params['itemTitle'] = item.team;
      }
    }
    if (params.query) {
      return this.analytics.track(type, params);
    }
  }

  customBuynow(
    itemId: string,
    message: string
  ) {
    this.itemAction(AnalyticsEvents.CustomBuynowPurchase, itemId, {
      message,
    });
  }
}
