import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { buildItemPath } from '../../../../helpers/build-item-path';
import { GlobalState } from '../../../../store';
import { processBid } from '../../../../store/bid/bid.actions';
import { selectBidCompleted, selectBidProcessing } from '../../../../store/bid/bid.selectors';
import { selectUserState } from '../../../../store/user/user.selectors';
import {
  Auction,
  BuynowItem,
  CCard,
  Currency,
  FulfillmentOption,
  FulfillmentOptionType,
  ItemType,
  ProfileAddress,
  TicketItem,
  UserProfile,
} from '../../../../types';
import { Foundation } from '../../../../types/foundation.model';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@dash-nx/ui-components';
import {
  OnboardingDialogComponent,
  OnboardingDialogData,
} from '../onboarding-dialog/onboarding-dialog.component';
import profileHasAddress from '../../../../helpers/profile-has-address';
import { TrackAnalyticsService } from '../../../../analytics/track-analytics.service';
import { AnalyticsEvents } from '../../../../analytics/analytics-events.enum';

@Component({
  selector: 'buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
})
export class BuyComponent implements OnDestroy, OnChanges {
  buying = false;
  currency?: Currency;
  foundation?: Foundation;
  subs: Subscription[] = [];
  profile?: UserProfile;
  card?: CCard;
  processing$ = this.store.select(selectBidProcessing);
  isTopBidder = false;
  winnerMessage: string | undefined;
  qty = 1;
  requireFulfillmentMethod = false;

  @Input() item?: TicketItem | BuynowItem;
  get buyNowItem() {
    if (!this.item || this.item.itemType === ItemType.Ticket) return undefined
    return this.item as BuynowItem;
  }
  waitingPurchase = false;
  pickupAvailable = false;
  shippingAvailable = false;
  purchaseCompleted = false;

  private _auction?: Auction | undefined | null;
  public get auction(): Auction | undefined | null {
    return this._auction;
  }
  @Input()
  public set auction(value: Auction | undefined | null) {
    this._auction = value;
    this.currency = this.auction?.foundation?.currency ?? 'USD';
    this.foundation = this.auction?.foundation;
  }

  constructor(
    private store: Store<GlobalState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private trackAnalytics: TrackAnalyticsService,
    private router: Router
  ) {
    const subs = this.store.select(selectUserState).subscribe(({ profile }) => {
      if (profile) this.profile = { ...profile };
    });
    this.subs.push(subs);

    // Listen for bid completion
    const bidCompleted = this.store
      .select(selectBidCompleted)
      .subscribe((completed) => {
        if (completed && this.waitingPurchase) {
          this.waitingPurchase = false;
          this.requireFulfillmentMethod = false;
          this.purchaseCompleted = true;
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {
                userBidId: null
              },
              queryParamsHandling: 'merge',
            }
          );
        }
      });
    this.subs.push(bidCompleted);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item.currentValue) {
      this.pickupAvailable = !!changes.item.currentValue?.fulfillmentOptions?.find((option: FulfillmentOption) => option.type === FulfillmentOptionType.pickup);
      this.shippingAvailable = !!changes.item.currentValue?.fulfillmentOptions?.find((option: FulfillmentOption) => option.type === FulfillmentOptionType.shipping);
    }
  }

  private notOnboarded() {
    if (!this.currency) return;
    const dialogRef = this.dialog.open<
      OnboardingDialogComponent,
      OnboardingDialogData
    >(OnboardingDialogComponent, {
      data: { currency: this.currency, foundationId: this.foundation?.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const data: ConfirmDialogData = {
        confirmKey: result.isNewProfile
          ? 'bid.newAccount'
          : 'bid.existingAccount',
        okKey: 'modal.ok',
        hideCancel: true,
        bodyKey: 'bid.goPurchase',
      };
      this.dialog.open(ConfirmDialogComponent, {
        data,
      });
      if (!this.card) this.card = result.card;
    });
  }

  submit() {
    if (!this.currency || !this.qty || !this.item?.id) return;
    this.trackAnalytics.buy(
      AnalyticsEvents.StartPurchase,
      this.item.id,
      this.qty
    );
    if (!this.card || !profileHasAddress(this.profile)) {
      return this.notOnboarded();
    }
    if (this.item.itemType === ItemType.Buynow && this.item.fulfillmentOptions?.length) {
      this.requireFulfillmentMethod = true;
      return;
    }
    this.completePurchase();
  }

  completePurchase(fulfillmentMethod?: {option: Partial<FulfillmentOption>, address?: ProfileAddress}) {
    const { routes } = buildItemPath(
      this.route.snapshot.data,
      this.route.snapshot.params
    );
    this.store.dispatch(
      processBid({
        bid: {
          step: 'saving',
          amount: this.qty,
          currency: this.currency!,
          item: this.item!,
          itemPath: [...routes, 'item', this.item!.id],
          type: 'purchase',
          card: this.card,
          fulfillmentOption: fulfillmentMethod?.option,
          shipAddress: fulfillmentMethod?.address
        },
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  addItem() {
    if (!this.item) return;
    const available =
      (this.item.maxItems || 0) - (this.item.purchasedCount || 0);
    if (
      this.item.maxPurchases > 1 &&
      this.qty < this.item.maxPurchases &&
      this.qty < available
    ) {
      this.qty += 1;
    }
  }

  subtractItem() {
    if (this.qty > 1) {
      this.qty -= 1;
    }
  }
  cardSelected(card: CCard) {
    setTimeout(() => (this.card = card));
  }

  confirmFulfillmentMethod(event: {option: Partial<FulfillmentOption>, address?: ProfileAddress}) {
    if (event?.option) {
      this.waitingPurchase = true;
      this.completePurchase(event);
    }
  }

  fulfillmentOptionState(event: string) {
    if (event === 'cancel') {
      this.requireFulfillmentMethod = false;
    }
  }

}
